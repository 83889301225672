@font-face {
  font-family: "BasisGrotesqueProMedium";
  src: url("../fonts/basis-grotesque-medium-pro.eot");
  src: url("../fonts/basis-grotesque-medium-pro.eot?#iefix") format("embedded-opentype"),
  url("../fonts/basis-grotesque-medium-pro.woff2") format("woff2"),
  url("../fonts/basis-grotesque-medium-pro.woff") format("woff"),
  url("../fonts/basis-grotesque-medium-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BasisGrotesqueProMedium";
  src: url("../fonts/basis-grotesque-medium-italic-pro.eot");
  src: url("../fonts/basis-grotesque-medium-italic-pro.eot?#iefix") format("embedded-opentype"),
  url("../fonts/basis-grotesque-medium-italic-pro.woff2") format("woff2"),
  url("../fonts/basis-grotesque-medium-italic-pro.woff") format("woff"),
  url("../fonts/basis-grotesque-medium-italic-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

$theme-colors: (
  "primary": #ff0852,
  "yellow": #ffea38,
  "blue": #4200ff,
);

$font-family-monospace: "IBM Plex Mono", monospace;
$font-family-sans-serif: "BasisGrotesqueProMedium", "Helvetica", "Arial", "sans-serif";
$font-family-base: $font-family-sans-serif;
$font-weight-medium: 500;

$link-decoration: underline;
$link-color: #000;

$btn-border-radius: 16px;

//@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/utilities";

$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (xxl: 1400px)
);

$container-max-widths: map-merge(
  $container-max-widths,
  (xxl: 1140px)
);