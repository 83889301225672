.page-header {
  position: fixed;
  padding: 0;
  top: 1rem;
  left: 0;
  z-index: 100;
  background: none;

  .logo {
    margin-right: 1rem;
    width: 68px;
    height: 44px;
  }

  .tagline {
    font-size: 0.8rem;
    text-transform: lowercase;
  }
}

@include media-breakpoint-down(md) {
  .signup-page .page-header {
    position: relative;
    padding-top: 1rem;
    background-color: theme-color("yellow");
    top: 0;
  }
}