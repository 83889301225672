.page-footer {
  position: fixed;
  padding: 0;
  background: none;
  left: 0;
  bottom: 1rem;
  z-index: 100;
}

.kofein-footer-logo {
  width: 74px;
  height: 7px;
}

@include media-breakpoint-down(md) {
  .signup-page .page-footer {
    position: relative;
    padding-bottom: 1rem;
    background-color: theme-color("yellow");
  }
}