$karl-width: 256px;
$karl-height: 400px;

$karl-md-width: 384px;
$karl-md-height: 600px;

$karl-xl-width: 447px;
$karl-xl-height: 700px;

.karl {
  max-height: 80vh;
  width: auto;
  height: 400px;
  z-index: 3;

  @include media-breakpoint-up(md) {
    height: 600px;
  }

  @include media-breakpoint-up(xl) {
    height: 700px;
  }
}

.wallpaper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: url('/assets/images/bg-pattern.jpg');

  @include media-breakpoint-up(md) {
    left: 15%;
  }
}

.frame {
  position: absolute;
  z-index: 4;
  height: auto;
}

.ipad {
  position: absolute;
  z-index: 4;
}

.karl-bubble {
  position: absolute;
  right: 0.5rem;
  width: 150px;
  height: 92px;
  padding: 0.5rem 0.8rem;
  z-index: 5;
  transition: transform 200ms cubic-bezier(0.28, 0.52, 0.58, 1.52);
  font-weight: $font-weight-bold;
  font-style: italic;
  font-size: 1.3rem;
  line-height: 1.2;
  font-family: "IBM Plex Sans", sans-serif;

  span {
    position: relative;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.animate {
    transform: scale(1);
  }

  @include media-breakpoint-up(md) {
    padding: 1rem 1.5rem;
    width: 300px;
    height: 184px;
    font-size: 2.2rem;
  }

  @include media-breakpoint-up(lg) {
    transform: scale(0);
  }

  @include media-breakpoint-up(xl) {
    font-size: 2.6rem;
  }
}