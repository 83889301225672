@import "karl";

.bg-slide-1 {
  background-color: #e2004b;
}

.home .signup-btn {
  display: inline-block !important;
}

.arrow {
  position: absolute;
  bottom: 1rem;
  height: 40px;
  width: 15px;
  z-index: 6;
}

.lead {
  font-family: $font-family-monospace;
  font-style: italic;
  font-weight: $font-weight-light;
  line-height: 1.2;
  font-size: 2rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: 2.6rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3.5rem;
  }

  strong {
    font-weight: $font-weight-bold;
    font-style: italic;
    text-decoration: underline;
  }
}

.slide-2 {
  background-color: #2313f7;
  color: #fff;

  .content-wrapper {
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      right: 0;
    }

    width: 100%;
    padding: 1.5rem;
    text-align: center;
  }
}

.slide-3 {
  border-top: 4px solid black;

  .content-wrapper {
    width: 100%;
    padding: 0 2rem;
    margin-top: 2rem;

    &:last-child {
      margin-bottom: 2rem;
    }

    p {
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 2.25rem;
      }
    }

    ul {
      @extend .list-unstyled;

      margin-bottom: 0;
      font-size: 1rem;

      li {
        $bullet-size: 13px;
        $bullet-margin: 0.8rem;

        border-top: 1px solid black;
        padding: 1rem 0 1rem calc(#{$bullet-size} + #{$bullet-margin});
        text-indent: calc((#{$bullet-size} + #{$bullet-margin}) * -1);

        &:last-child {
          border-bottom: 1px solid black;
        }

        &::before {
          content: "";
          display: inline-block;
          margin-right: $bullet-margin;
          width: $bullet-size;
          height: $bullet-size;
          background-color: #00d7a7;
          border-radius: 50%;
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;

        li {
          $bullet-size: 15px;
          $bullet-margin: 2rem;

          padding: 2rem 0 2rem calc(#{$bullet-size} + #{$bullet-margin});
          text-indent: calc((#{$bullet-size} + #{$bullet-margin}) * -1);

          &:last-child {
            border-bottom: 1px solid black;
          }

          &::before {
            content: "";
            display: inline-block;
            margin-right: $bullet-margin;
            width: $bullet-size;
            height: $bullet-size;
            background-color: #00d7a7;
            border-radius: 50%;
          }
        }
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .left-side {
      min-height: 100vh;
    }

    .right-side {
      padding-bottom: 0;
      min-height: 100vh;
      border-top: 0;
      border-left: 1px solid black;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: 200vh;

    .content-wrapper {
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .right-side {
    padding-bottom: 2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 74%, rgba(78, 254, 201, 1) 100%);
  }
}

.slide-4 {
  background-color: theme-color("yellow");

  .content-wrapper {
    min-height: 50vh;
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      margin-top: 5rem;
    }
  }

  .gallery-icon {
    width: auto;
    height: 60px;

    @include media-breakpoint-up(lg) {
      height: 165px;
    }
  }

  .app-icon {
    margin-right: 2rem;
    width: auto;
    height: 60px;

    @include media-breakpoint-up(lg) {
      height: 120px;
    }
  }

  .instruction {
    font-size: 1rem;
    font-family: $font-family-monospace;
    font-style: italic;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 2.15rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .content-wrapper {
      min-height: 100vh;
    }
  }
}

.slide-5 {
  p {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 2.25rem;
    }
  }

  .left-side,
  .right-side {
    margin-top: 2rem;
  }

  @include media-breakpoint-up(md) {
    .left-side {
      padding: 0 2rem;
      margin-top: 0;
      min-height: 100vh;
    }

    .right-side {
      margin-top: 0;
      min-height: 100vh;
      border-left: 1px solid black;
      border-top: 0;
    }
  }

  p.small-text {
    font-size: 0.8rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
}

.eli-wrapper {
  $bubble-width: 200px;
  $bubble-height: 113px;

  $bubble-md-width: 230px;
  $bubble-md-height: 130px;

  $bubble-lg-width: 300px;
  $bubble-lg-height: 169px;

  $bubble-xl-width: 400px;
  $bubble-xl-height: 225px;

  padding-top: calc(#{$bubble-height} / 4.5);
  padding-left: calc(#{$bubble-width} / 1.6);

  .eli-bubble {
    position: absolute;
    top: 0;
    left: 0;
    width: $bubble-width;
    height: $bubble-height;
    transform: scale(0);
    transform-origin: center;
    transition: transform 400ms cubic-bezier(0.56, 0.61, 0.53, 1.54) 900ms;
  }

  .hello {
    position: absolute;
    width: 40%;
    top: 1rem;
    left: 1rem;
    font-weight: $font-weight-bold;
    font-style: italic;
    font-size: 1.3rem;
    line-height: 1.2;
    font-family: "IBM Plex Sans", sans-serif;
    transform: scale(0);
    transform-origin: center;
    transition: transform 400ms cubic-bezier(0.56, 0.61, 0.53, 1.54) 1200ms;
  }

  .eli-svg {
    position: relative;
    width: 150px;
  }

  .eli-line {
    transform: translate(100%, 0);
    opacity: 0;
    transition: all 600ms linear;
  }

  .eli-picture {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: transform 350ms ease-out 700ms;
  }

  &.animate {
    .eli-line {
      transform: translate(0);
      opacity: 1
    }

    .eli-picture {
      transform: scaleY(1);
      opacity: 1;
    }

    .eli-bubble,
    .hello {
      transform: scale(1);
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: calc(#{$bubble-md-height} / 4.5);
    padding-left: calc(#{$bubble-md-width} / 1.6);

    .eli-bubble {
      width: $bubble-md-width;
      height: $bubble-md-height;
    }

    .hello {
      top: 0.5rem;
      left: 1rem;
      font-size: 1.6rem;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: calc(#{$bubble-lg-height} / 4);
    padding-left: calc(#{$bubble-lg-width} / 2);

    .eli-bubble {
      width: $bubble-lg-width;
      height: $bubble-lg-height;
    }

    .hello {
      top: 1rem;
      left: 2rem;
      font-size: 2.2rem;
    }

    .eli-svg {
      width: 330px;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-top: calc(#{$bubble-xl-height} / 4.5);
    padding-left: calc(#{$bubble-xl-width} / 1.6);

    .eli-bubble {
      width: $bubble-xl-width;
      height: $bubble-xl-height;
    }

    .hello {
      top: 1rem;
      left: 2rem;
      font-size: 2.6rem;
    }
  }
}
