.form-section {
  min-height: 100vh;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  background-color: theme-color("yellow");
}

.form-wrapper {
  h2,
  .h2 {
    font-size: 1.3rem;
    font-family: $font-family-monospace;
    font-style: italic;

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }
}

.signup-form {
  position: relative;
  background-color: #fff;
  padding: 1rem 0.75rem;
  border: 2px solid #000;

  .form-label {
    margin-left: 0.5rem;
    font-size: 0.65rem;
    text-transform: uppercase;
  }

  input.form-control {
    border-radius: 0;
    border: none;
    background-color: #ebebeb;

    &.error {
      border: 1px solid theme-color("primary");
    }
  }

  .error-message {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    color: theme-color("primary");
    font-size: 0.8rem;
  }

  .response {
    width: 100%;
    font-size: 0.8rem;

    &.error {
      color: theme-color("primary");
    }

    &.success {
      color: darkgreen;
    }
  }

  .btn {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    font-size: 0.8rem;
  }
}

.form-bubble {
  border-radius: 50%;
  padding: 1rem;
  position: absolute;
  width: 140px;
  height: 140px;
  top: -45px;
  right: -25px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $font-family-monospace;
  font-style: italic;
  color: #fff;
  background-color: theme-color("blue");
  font-weight: $font-weight-light;

  @include media-breakpoint-up(sm) {
    width: 150px;
    height: 150px;
    //top: -35px;
    //right: -25px;
    top: -75px;
    right: -75px;
    font-size: 1rem;
  }

  @include media-breakpoint-up(md) {
    width: 190px;
    height: 190px;
    //top: -60px;
    //right: -35px;
    top: -95px;
    right: -95px;
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(lg) {
    width: 220px;
    height: 220px;
    //top: -70px;
    //right: -100px;
    top: -110px;
    right: -110px;
    font-size: 1.35rem;
  }

  @include media-breakpoint-up(xxl) {
    width: 250px;
    height: 250px;
    //top: -100px;
    //right: -100px;
    top: -125px;
    right: -125px;
    font-size: 1.5rem;
  }
}