@import "common";
@import "header";
@import "footer";
@import "form";
@import "home";

.text-decoration-underline {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.btn {
  text-transform: lowercase;
}

.btn-primary {
  @include button-variant(theme-color("primary"), #000);
}

.btn-black {
  @include button-variant(#000, #fff);
}

.signup-btn {
  font-size: 0.8rem;
  border-radius: 32px;

  @include media-breakpoint-up(lg) {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
}